<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Gift Cards Type</h4>
                  <div class="">
                    <router-link :to="{ name: 'create_giftcards_type' }">
                      <button class="btn btn-default custom-orange">
                        <i class="fa fa-plus"></i> New gift card Type
                      </button>
                    </router-link>
                  </div>

                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <input aria-invalid="false" v-model="search" @keyup="getVoucherTypes()" aria-required="false"
                        autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search"
                        class="form-control">
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
            </div>
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table_section">
                      <table class="table  table-hover">
                        <thead>
                          <tr>
                            <th width="5px">#</th>
                            <th>ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Description</th>
                            <th scope="col">Image</th>
                            <th scope="col">Created By</th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading">
                          <tr v-for="(giftcardtype, indx) in gitCardsType" :key="indx">
                            <td>{{ indx + 1 }}.</td>
                            <td>
                              <router-link :to="{ name: 'update_giftcards_type', params: { id: giftcardtype.id } }">
                                {{ giftcardtype.id }}
                              </router-link>
                            </td>
                            <td>
                              <router-link :to="{ name: 'update_giftcards_type', params: { id: giftcardtype.id } }">
                                {{ giftcardtype.voucher_name }}
                              </router-link>

                            </td>
                            <td>

                              <button
                                :class="giftcardtype.status == 1 ? 'btn btn-success dropdown-toggle' : 'btn btn-danger dropdown-toggle'"
                                type="button" :id="'dropdownMenuButton' + giftcardtype.id" data-toggle="dropdown"
                                aria-expanded="false">
                                <span v-if="giftcardtype.status == 1">
                                  Published
                                </span>
                                <span v-else-if="giftcardtype.status == 2">
                                  Unpublished
                                </span>
                                <span v-else-if="giftcardtype.status == 0">
                                  Unpublished
                                </span>
                                <span v-else-if="giftcardtype.status == 3">Archived</span>
                                <span v-else-if="giftcardtype.status == 4">Trashed</span>
                              </button>
                              <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton' + giftcardtype.id">
                                <a class="dropdown-item" @click="updateStatus(1, giftcardtype.id)" href="#">Publish</a>
                                <a class="dropdown-item" @click="updateStatus(2, giftcardtype.id)"
                                  href="#">Unpublish</a>
                                <a class="dropdown-item" @click="updateStatus(3, giftcardtype.id)" href="#">Archive</a>
                                <a class="dropdown-item" @click="updateStatus(4, giftcardtype.id)" href="#">Trash</a>
                              </div>



                            </td>
                            <td>
                              {{ formatPrice(giftcardtype.voucher_amount) }}
                            </td>
                            <td>
                              <div v-html="giftcardtype.voucher_desc"></div>
                            </td>
                            <td>
                              <img :src="giftcardtype.voucher_image" alt="" border=3 height=100 width=130>
                            </td>
                            <td>
                              <span>{{ giftcardtype.user_name ? giftcardtype.user_name : "N/A" }}</span>
                            </td>
                          </tr>

                        </tbody>
                      </table>

                    </div>
                    <div v-if="loading" class="loading-cover">
                      <div class="loader"> </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
export default {

  data() {
    return {
      search: '',
      debounce: null,
      loading: false,
      url: '',
      gitCardsType: [],
    }
  },
  created: async function () {
    this.getVoucherTypes();
    const url = this.dashBaseUrl
    this.url = url.replace("/api/v1", "/")
  },
  methods: {
    async getVoucherTypes() {
      this.loading = true;
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      let url = this.dashBaseUrl + "/vouchers/" + this.merchantID;
      if (this.search !== '') {
        url = url + '?search=' + this.search
      }
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          this.loading = false;
          this.gitCardsType = response.data.data;
        }
      }).catch((err) => {
        this.loading = false;
        console.log('error', err);
      });
    },
    async updateStatus(status, id) {
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = `${this.dashBaseUrl}/voucher-status/${id}/${this.merchantID}` ;
      const payload = {
        'status': status
      };
      this.axios.put(url, payload, config).then((response) => {
        if (response.status === 200) {
          this.getVoucherTypes();
        }
      })
        .catch((err) => {
          console.log('error', err);
        })


    },

  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
